<template>
  <div>
    <v-dialog v-if="isDialog" v-model="$store.state.review.isReviewVisible"
    persistent
    width="500">
      <v-card>
        <v-card-title class="u-blue-text">
            <p style="width:100%" class="mb-0">
                {{ $t('Review') }}
                <i class="mdi mdi-close-circle-outline float-right"
                @click="closeReviewDialog()"></i>
            </p>
        </v-card-title>
        <v-card-text>
           <v-divider></v-divider>
            <div class="mb-5 mt-5">
                <v-layout class="u-blue-text mb-0 text-header">
                    <h3 style="margin:0px !important">
                      {{this.$store.state.review.reviewDetails.mechanic}}
                    </h3>
                </v-layout>
                <star-rating @rating-selected="rating = $event" :rating="rating"
                rounded-corners="true" active-color="#198655" star-size="20" border-width="2"
                border-color="#198655" inactive-color="white" text-class="d-none"></star-rating>
            </div>
            <v-textarea
                solo
                elevation="0"
                v-model="comment"
                name="input-7-4"
                placeholder="Add your comment here...">
            </v-textarea>
            <v-layout justify-end>
                <v-btn outlined color="#198655" @click="dismiss">{{ $t('Dismiss') }}</v-btn>
                <v-btn color="#198655" class="white--text ml-2"
                :loading="$store.state.review.processingRequest"
                @click="giveRating">{{ $t('Send') }}</v-btn>
            </v-layout>
        </v-card-text>
        <v-card-actions>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card v-if="!isDialog">
        <v-card-text>
            <div class="mb-5 mt-5">
                <v-layout class="u-blue-text mb-0 text-header">
                    <h3 style="margin:0px !important">
                      {{this.$store.state.review.reviewDetails.mechanic}}
                    </h3>
                </v-layout>
                <star-rating @rating-selected="rating = $event" :rating="rating"
                rounded-corners="true" active-color="#198655" star-size="20" border-width="2"
                border-color="#198655" inactive-color="white" text-class="d-none"></star-rating>
            </div>
            <v-textarea
                elevation="0"
                solo
                v-model="comment"
                name="input-7-4"
                placeholder="Add your comment here...">
            </v-textarea>
            <v-layout justify-end>
                <v-btn color="#198655" class="white--text ml-2"
                :loading="$store.state.review.processingRequest"
                @click="giveRating">{{ $t('Send') }}</v-btn>
            </v-layout>
        </v-card-text>
        <v-card-actions>
        </v-card-actions>
      </v-card>
  </div>
</template>

<script>
import StarRating from 'vue-star-rating';
import ReviewDetails from '../store/model/ReviewDetails';

export default {
  components: { StarRating },
  data() {
    return {
      rating: 1,
      comment: '',
    };
  },
  props: {
    isDialog: {
      default: true,
      type: Boolean,
    },
  },
  methods: {
    closeReviewDialog() {
      this.$store.state.review.isReviewVisible = false;
      this.rating = 1;
      this.comment = '';
    },
    giveRating() {
      const currentReviewDetail = this.$store.state.review.reviewDetails;
      const review = new ReviewDetails(this.rating, this.comment,
        currentReviewDetail.mechanic, currentReviewDetail.id);
      this.$store.dispatch('review/giveRating', review)
        .then((resolve) => {
          this.$snotify.success(resolve);
          if (!this.isDialog) {
            this.$router.push({ name: 'Home' });
          }
          this.closeReviewDialog();
          this.retrieveAppointments();
        }, (error) => {
          this.$snotify.error(error);
        });
    },
    dismiss() {
      this.$store.dispatch('review/dismiss', this.$store.state.review.reviewDetails.id)
        .then((resolve) => {
          this.$snotify.success(resolve);
          this.closeReviewDialog();
          this.retrieveAppointments();
        }, (error) => {
          this.$snotify.error(error);
        });
    },
    retrieveAppointments() {
      if (this.$store.state.authenticate.UserLogged) {
        this.$store.dispatch('appointment/loadAppointmentsByUserId', { userId: localStorage.getItem('sessionId'), isGetAllAppointment: false });
      }
    },
  },
};
</script>
